import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import { useLocation } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Courses from "./pages/Courses";
import FreeTutorial from "./pages/FreeTutorial";
import Gallery from "./pages/Gallery";
import Admin from "./components/Admin/Admin";
import Footer from "./components/Footer/Footer";
import SuperAdmin from "./components/Admin/SuperAdmin";
import backend from "./backend";
import ClearIcon from "@mui/icons-material/Clear";
import "./App.css";
import ScrollTop from "./ScrollTop";
import StudentPanel from "./components/Student/StudentPanel";
import TeacherPanel from "./components/Teacher/TeacherPanel";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import ProtectedRoute1 from "./utils/ProtectedRoute1";
import ProtectedRoute2 from "./utils/ProtectedRoute2";
import ProtectedRoute3 from "./utils/protectedRoute3";

function App() {
  const [imgsrc, setImgsrc] = useState("");
  const location = useLocation();

  const [displayState, setDisplayState] = useState("none");
  const getBanner = async () => {
    try {
      const response = await fetch(`${backend}popup/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const resJson = await response.json();

      if (response.status === 200) {
        const size = resJson.length;
        if (size > 1) {
          setImgsrc(resJson[size - 1].popupImage);
        } else setImgsrc(resJson[0].popupImage);
      } else {
        console.log("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/freetutorial" ||
      location.pathname === "/courses" ||
      location.pathname === "/gallery" ||
      location.pathname === "/about" ||
      location.pathname === "/contact"
    )
      getBanner();
    if (imgsrc !== "") {
      setTimeout(() => {
        setDisplayState("flex");
      }, 10000);
    }
  }, [imgsrc, location]);

  return (
    <>
      <div
        style={{
          height: "100%",
          width: "100%",
          position: "fixed",
          zIndex: 1000,
          display: displayState,
          justifyContent: "center",
          backgroundColor: "rgba(10,10,10,.75",
          alignItems: "center",
        }}
      >
        <img
          src={imgsrc}
          className="banner__img"
          alt=""
          style={{ opacity: 1 }}
        />
        <div style={{ height: "80%" }}>
          <div onClick={() => setDisplayState("none")}>
            <ClearIcon
              // color="success"
              fontSize="large"
              style={{ display: "absolute", cursor: "pointer", color: "red" }}
            />
          </div>
        </div>
      </div>
      <ScrollTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/freetutorial" element={<FreeTutorial />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/login" element={<Admin />} />
        <Route
          path="/admin/superadmin"
          element={
            <ProtectedRoutes>
              <SuperAdmin />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/admin/office1"
          element={
            <ProtectedRoute1>
              <SuperAdmin />
            </ProtectedRoute1>
          }
        />
        <Route
          path="/admin/office2"
          element={
            <ProtectedRoute2>
              <SuperAdmin />
            </ProtectedRoute2>
          }
        />
        <Route
          path="/admin/office3"
          element={
            <ProtectedRoute3>
              <SuperAdmin />
            </ProtectedRoute3>
          }
        />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/student" element={<StudentPanel />} />
        <Route path="/teacher" element={<TeacherPanel />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
