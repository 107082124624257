import React from 'react'
import {Navigate} from "react-router-dom"
import Cookies from "universal-cookie"


const ProtectedRoute3 = ({children}) => {

    const cookie = new Cookies()


    const user = cookie.get("office3token")

    if(!user) {
        return <Navigate to="/login" replace />
    }
 return children

};

export default ProtectedRoute3;