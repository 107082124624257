import React from "react";

const ContactInfo = () => {
  return (
    <div className="col-md-4 " style={{marginBottom:"20px"}}>
      <p style={{ color: "white", fontSize: "16px", fontWeight: 600 }}>
        Call Us <br />
        <a href="tel:+91 8001100314"> <span style={{ color: "#3fe503dd" }}>+91 8001100314</span></a>
      </p>

      <p style={{ color: "white", fontSize: "16px", fontWeight: 600 }}>
        Mail Us <br />
        <a href=" mailto:Lakshyam.office0799@gmail.com"><span style={{ color: "#3fe503dd" }}>Lakshyam.office0799@gmail.com</span></a> 
      </p>
      <h5 style={{fontWeight:"bold" , textDecoration:"underline", color:"white"}}>
        Building 1
      </h5>
      <p style={{ color: "white", fontSize: "16px", fontWeight: 600 }}>
        59, Ram Gulam Singh Road, opp. Durga Mandir, Ushagram, Asansol, West Bengal 713303
      </p>
      <h5 style={{fontWeight:"bold" , textDecoration:"underline", color:"white"}}>
        Building 2
      </h5>
      <p style={{ color: "white", fontSize: "16px", fontWeight: 600 }}>
        Hari Gopal Complex, Beside Signature Hotel, Ushagram, Asansol, West Bengal 713303
      </p>

      <h5 style={{fontWeight:"bold" , textDecoration:"underline" , color:"white"}}>
        Building 3
      </h5>
      <p style={{ color: "white", fontSize: "16px", fontWeight: 600 }}>
        Lakshyam Coaching, above Calcutta Furnitures, Beside Volvo Bus Stand, Mother Teresa Road, Cheli-Danga Asansol
      </p>
    </div>
  );
};

export default ContactInfo;
