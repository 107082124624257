import React from 'react'
import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { darken, lighten } from '@mui/material';


const ContactDetails = ({generalContactList}) => {


    const baseBackgroundColor =
       'rgba(22, 34, 57, 0.04)';
    const columns = useMemo(
        () => [
          {
            accessorKey: 'contactName', //access nested data with dot notation
            header: 'Name',
          },
          {
            accessorKey: 'contactEmail',
            header: 'Email',
          },
          {
            accessorKey: 'contactPhone', //normal accessorKey
            header: 'Phone',
          },
          {
            accessorKey: 'contactMessage',
            header: 'Message',
          },
          {
            accessorKey: 'contactApply',
            header: 'Apply',
          },
        ],
        [],
      );
    
      const table = useMaterialReactTable({
        columns,
        data : generalContactList, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        enableColumnResizing: true,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0',
        color:"white"
      },
    },
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: darken(baseBackgroundColor, 0.1),
            color:"white"
          },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          {
            backgroundColor: darken(baseBackgroundColor, 0.2),
            color:"white"

          },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: lighten(baseBackgroundColor, 0.1),
            color:"white"

          },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          {
            backgroundColor: darken(baseBackgroundColor, 0.2),
            color:"white"

          },
      }),
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: baseBackgroundColor,
      draggingBorderColor: theme.palette.secondary.main,
      menuBackgroundColor : "white",
      
    }),
    muiTableHeadCellProps:{
        sx:{
            color:"white"
        }
    },
    enableTopToolbar:false
  });

  return (
    <>
    <MaterialReactTable table={table} color />
    </>
  )
}

export default ContactDetails